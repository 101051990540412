import Icon from '@components/icons'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useState, useEffect } from 'react'
import styles from './Footer.module.css'
import { Text, useUI } from '@components/ui'
import { useRouter } from 'next/router'
import CountryModalComp from './CountryModalComp'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const FooterNew = ({ metafields, data, pageType }) => {
  const [index, setIndex] = useState()
  const { locale } = useRouter()
  const { countryModal, setCountryModal } = useUI()
  const { setRelativeLink } = useRelativeLink()

  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  const COUNTRY_CHOOSE = (className = '') => {
    return (
      <>
        {footerCountry?.current_country && (
          <div className={`${styles.country_wrap} ${className}`}>
            {/* <p className={styles.current_country_text}>{data?.select}</p> */}
            <button
              className={styles.country}
              onClick={() =>
                setCountryModal({
                  visible: true,
                  position: 'footer',
                })
              }
            >
              <div className={styles.current_country_btn}>
                <Icon
                  iconKey="countries"
                  className="h-[17px] w-[17px] fill-white"
                />
                <p className={styles.current_country}>
                  {footerCountry?.current_country}
                </p>
              </div>
            </button>
          </div>
        )}
      </>
    )
  }
  const [isActive, setIsActive] = useState(false)
  const {
    footerSetting = {},
    footerMenu = {},
    copywriting,
    infos = {},
    footerCountry = {},
  } = metafields
  return (
    <section
      className={cn(styles.footer_layer, 'layer', 'global_footer_layer')}
    >
      <div className={cn(styles.footer_content, 'content')}>
        <div
          className={cn(styles.footer_menu_contact_us, {
            [styles.footerMenuWrap]: !data?.aboutUs || !data.aboutUs.length,
          })}
        >
          <div className="flex w-full justify-between md:flex-col">
            <div className="flex w-full gap-[16px] xl:flex-col-reverse">
              {data?.aboutUs &&
                data?.aboutUs.map((v, i) => (
                  <div key={i} className={cn(styles.footer_aboutUs)}>
                    <p
                      className={styles.footer_menu_title}
                      dangerouslySetInnerHTML={{
                        __html: v.title,
                      }}
                    ></p>
                    <div className={styles.footer_aboutUs_content}>
                      {v.content &&
                        v.content.map((item, index) => (
                          <div
                            key={index}
                            className={styles.footer_aboutUs_all}
                          >
                            <Icon
                              aria-label="anker link"
                              iconKey={item.icon}
                              className={cn(
                                styles.footer_aboutUs_all_icon,
                                'h-[20px] w-[20px]'
                              )}
                            />
                            <div className="flex flex-col justify-center xl:justify-start">
                              <div className="flex cursor-pointer">
                                {item?.country && (
                                  <p
                                    className={styles.footer_aboutUs_country}
                                    dangerouslySetInnerHTML={{
                                      __html: item.country,
                                    }}
                                  ></p>
                                )}
                                {item?.linkText && (
                                  <a
                                    target="_self"
                                    href={`${setRelativeLink({
                                      link: item?.link,
                                    })}`}
                                    aria-label={item.linkText}
                                    className={cn(styles.footer_aboutUs_a, {
                                      [styles.liveChat]:
                                        item?.type == 'liveChat',
                                    })}
                                    onClick={(e) =>
                                      item?.type == 'liveChat' &&
                                      handleLiveChat(e)
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: item.linkText,
                                    }}
                                  ></a>
                                )}
                              </div>
                              {item.text && (
                                <p
                                  className={styles.footer_aboutUs_text}
                                  dangerouslySetInnerHTML={{
                                    __html: item.text,
                                  }}
                                ></p>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="xl:hidden ">
              <div className={styles.media_icon_box}>
                {data?.media_list &&
                  data?.media_list.map((v, i) => (
                    <a
                      key={i}
                      href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                        pageType === 'powerStation' || pageType === 'hes'
                          ? '_pps'
                          : ''
                      }`}
                      className={styles.media_icon}
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        aria-label="anker link"
                        iconKey={v.icon}
                        className={cn(styles.icon)}
                      />
                    </a>
                  ))}
              </div>
              {infos?.store_payWithEase?.title && (
                <div className={cn(styles.commonInfos, styles.intro)}>
                  <div className={styles.payWithEaseContent}>
                    <Text
                      className="w-full max-w-xl break-words"
                      html={infos?.store_payWithEase.iconList}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <CountryModalComp
            footerCountry={footerCountry}
            pageType={pageType}
            locale={locale}
          />
        </div>
        <div className={styles.footer_menu_all}>
          {data?.menu_list &&
            data?.menu_list.map((v, i) => (
              <div key={i} className={styles.footer_menu}>
                <div
                  className={styles.footer_menu_title_mob}
                  onClick={() => {
                    setIndex(i)
                    if (index == i) {
                      setIsActive(!isActive)
                    } else {
                      setIsActive(true)
                    }
                  }}
                >
                  <p
                    className={styles.footer_menu_title}
                    dangerouslySetInnerHTML={{
                      __html: v.title,
                    }}
                  ></p>

                  <div className="relative hidden xl:block">
                    {index == i && isActive ? (
                      <MinusIcon className="h-[12px] w-[12px] text-white " />
                    ) : (
                      <PlusIcon className="h-[12px] w-[12px] text-white" />
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    {
                      [styles.footer_menu_text_box_show]:
                        index == i && isActive,
                    },
                    {
                      [styles.footer_menu_text_box_hide]:
                        index !== i || !isActive,
                    },

                    styles.footer_menu_text_box
                  )}
                >
                  {v.list &&
                    v.list.map((item, index) => (
                      <p key={index} className={styles.footer_menu_a_box}>
                        <a
                          className={styles.footer_menu_a}
                          href={`${setRelativeLink({
                            link: item?.link,
                          })}?ref=footer${
                            pageType === 'powerStation' || pageType === 'hes'
                              ? '_pps'
                              : ''
                          }`}
                          target={item.target}
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                          rel={item?.rel}
                        ></a>
                      </p>
                    ))}
                </div>
              </div>
            ))}
        </div>
        <div className={styles.footer_media}>
          <div className={styles.media_icon_box}>
            {data?.media_list &&
              data?.media_list.map((v, i) => (
                <a
                  key={i}
                  href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                    pageType === 'powerStation' || pageType === 'hes'
                      ? '_pps'
                      : ''
                  }`}
                  className={styles.media_icon}
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <Icon
                    aria-label="anker link"
                    iconKey={v.icon}
                    className={cn(styles.icon, 'w-[40px] xl:w-[32px]')}
                  />
                </a>
              ))}
          </div>
          {COUNTRY_CHOOSE(styles.desktop_hide)}
        </div>
        <div className="flex items-center justify-between gap-[40px] border-b border-[#fff] border-opacity-20 pb-[16px] pt-[40px] xl:pb-[12px] xl:pt-0">
          <div className=" flex items-center gap-[64px] md:flex-col md:items-start md:!gap-[16px] xl:gap-[42px]">
            <a
              href={`${setRelativeLink({
                link: data?.ankerin?.link,
              })}?ref=footer${
                pageType === 'powerStation' || pageType === 'hes' ? '_pps' : ''
              }`}
              className={styles.anker_icon}
              target={data?.ankerin?.target}
              rel="noopener noreferrer"
            >
              <Icon
                aria-label="anker link"
                iconKey={data?.ankerin?.icon}
                className={cn(styles.icon_ankerin)}
              />
            </a>
            <div className={styles.footer_icon_box}>
              {data?.icon_list &&
                data?.icon_list.map((v, i) => (
                  <a
                    key={i}
                    href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                      pageType === 'powerStation' || pageType === 'hes'
                        ? '_pps'
                        : ''
                    }`}
                    className={styles.anker_icon}
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      aria-label="anker link"
                      iconKey={v.icon}
                      className={cn(styles.footer_icon)}
                    />
                  </a>
                ))}
            </div>
          </div>

          <div className="xl:hidden">{COUNTRY_CHOOSE(styles.desktop_hide)}</div>
        </div>
        <div className="mt-[16px] flex xs:flex-wrap xl:mt-[12px] xl:flex-col xl:items-start">
          <p
            className={styles.footer_recordNumber}
            dangerouslySetInnerHTML={{
              __html: data?.recordNumber,
            }}
          ></p>
          <div className="ml-[10px] xl:ml-0 xl:flex xl:flex-wrap">
            {data?.footer_link_list &&
              data?.footer_link_list.map((v, i) => (
                <a
                  key={i}
                  className={styles.footer_link_list}
                  href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                    pageType === 'powerStation' || pageType === 'hes'
                      ? '_pps'
                      : ''
                  }`}
                  target="_self"
                  dangerouslySetInnerHTML={{
                    __html: v.text,
                  }}
                ></a>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default FooterNew
